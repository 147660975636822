document.addEventListener('DOMContentLoaded', function() {

  function toggleMobileNav(visible) {
    $('#mobile-nav').toggleClass('d-none', !visible);
    $('body').toggleClass('no-scroll', visible);
  }

  $("a.tenf-menu-hamburger").on("click", function() {
    toggleMobileNav(true);
  });
  $("a.tenf-close-menu-button").on("click", function() {
    toggleMobileNav(false);
  });


  function setMobileNavHeight() {
    let top = $("#mobile-nav-top");
    let bot = $("#mobile-nav-bottom");
    let topHeight = top.outerHeight(true);
    let windowHeight = $( window ).height();
    bot.css("max-height", (windowHeight - topHeight) + "px");
  }
  setMobileNavHeight();

  $( window ).resize(function() {
    setMobileNavHeight();
  });


});
